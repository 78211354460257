
    import { v4 as uuidv4 } from 'uuid';
    import gsap from '~/mixins/gsap';
    export default {
        mixins: [gsap],
        data() {
            return {
                timeOutHolder : null,
                timeOutTime : 5000,
                currentIndex : 1,
                getUrlForLanguage(l){
                    return this.$route.fullPath.replace(this.currentLanguage+'/',l+'/');
                },
                languages : [
                    {
                        _uid : uuidv4(),
                        path : 'it',
                        label : "IT",
                        locale : 'it-IT'
                    },
                    {
                        _uid : uuidv4(),
                        path : 'en',
                        label : "EN",
                        locale : 'en-US'
                    },
                    {
                        _uid : uuidv4(),
                        path : 'fr',
                        label : "FR",
                        locale : 'fr-FR'
                    }
                ],
                textes : null,
                alreadyOnRequest : false
            }
        },
        computed : {
            currentLanguage : {
                get(){
                    return this.$route && this.$route.params && this.$route.params.lang && this.$route.params.lang.length == 2 ? this.$route.params.lang : this.$config.defaultLanguage;
                }
            },
            top(){ return this.$store.state.scroll.top }
        },
        mounted() {
            this.getTopRow();
        },
        methods: {
            getTopRow(){
                if(!this.textes && !this.alreadyOnRequest){
                    this.alreadyOnRequest = true;
                    this.$axios.get("storyblok/stories/system/maintoprow",{params:{flushcache : 'yes'}}).then(axRes => {
                        this.alreadyOnRequest = false;
                        this.textes = axRes.data.story.content.items;
                        console.log(this.textes)
                        console.log('textes ',this.textes.map(text=> ({text: text.text, link : text.link.cached_url})))
                        if(this.textes.length){
                            this.initGsapAnimationsText();
                        }
                    }).catch(axErr => {
                        this.alreadyOnRequest = false;
                        console.log(axErr.stack);
                    })
                }
            },
            initGsapAnimationsText(){
                if(!this.$el || !this.$el.querySelector(".text")){
                    this.$lerpfunction(this.initGsapAnimationsText,100,this);
                }else{ 
                    this.gsap.set(this.$el.querySelector(`.text:nth-child(${this.currentIndex})`),{opacity:1, zIndex: 1});
                    if(this.textes.length >1) this.timeOutHolder = setTimeout(this.changetext,this.timeOutTime);
                }
            },
            changetext(){
                const currentEl = this.$el.querySelector(`.text:nth-child(${this.currentIndex})`);
                const nextIndex = this.currentIndex + 1 > this.textes.length ? 1 : this.currentIndex + 1;
                const nextEl = this.$el.querySelector(`.text:nth-child(${nextIndex})`);
                const changeTextTl = this.gsap.timeline({"onComplete":() => {
                    this.currentIndex = nextIndex;
                }});
                changeTextTl.fromTo(currentEl,{y:0,opacity:1, zIndex : 1},{y:-20,opacity:0, zIndex : 0});
                changeTextTl.fromTo(nextEl,{y:20,opacity:0, zIndex: 0},{y:0,opacity:1, zIndex: 1});
                this.timeOutHolder = setTimeout(this.changetext,this.timeOutTime);
            },
        },
        beforeDestroy(){
            if(this.timeOutHolder) clearTimeout(this.timeOutHolder);
        }
    }
